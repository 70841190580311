// Replaces JS stack with Python traceback if provided
export const getWireErrorFromResponse = async (response: Response, replaceStack = false) => {
  const json = await response.json();

  let message: string;
  if ('detail' in json) {
    message = typeof json.detail === 'string' ? json.detail : JSON.stringify(json.detail, null, 2);
  } else if (!replaceStack && 'traceback' in json) {
    message = json.traceback;
  } else {
    message = json != null ? JSON.stringify(json, null, 2) : '';
  }

  const error = new Error(message ?? 'Error');

  // So we can show the Python stack trace on error pages
  if (replaceStack && json.traceback) {
    error.stack = json.traceback;
  }

  return error;
};
